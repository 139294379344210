import { Col, Row, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useQuery } from 'react-query';

import SelectLookupDto from '../../../../components/SelectLookupDto/SelectLookupDto';

import useLocale from '../../../../hooks/useLocale';
import { USE_QUERY_OPTIONS, REFERRAL_SOURCE_TYPES_QUERY, INTAKE_SOURCES_QUERY } from '../../../../constants/reactQuery';
import API from '../../../../utils/api';

import '../DebtorProfileContent.scss';

interface IProps {
  disabled?: boolean;
}

const SourceInformation = ({ disabled = false }: IProps) => {
  const { t } = useLocale();

  const { data: referralSourceTypes } = useQuery(
    [REFERRAL_SOURCE_TYPES_QUERY],
    () => API.listActiveReferralSourceTypes(),
    USE_QUERY_OPTIONS
  );

  const { data: intakeSources } = useQuery([INTAKE_SOURCES_QUERY], () => API.listIntakeSources(), USE_QUERY_OPTIONS);

  return (
    <Form.Item noStyle shouldUpdate>
      <Row gutter={20} align="top">
        <Col span={8}>
          <Row gutter={20}>
            <Col span={22}>
              <Form.Item
                label={t.DEBTOR_PROFILE_HEAR_ABOUT_US}
                name="referralSourceTypeId"
                required
                rules={[{ required: true, message: t.REQUIRED_FIELD }]}
              >
                <SelectLookupDto data={referralSourceTypes} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={8} pull={1} style={{ paddingLeft: 30 }}>
          <Form.Item
            label={t.DEBTOR_PROFILE_INTAKE_SOURCE}
            name="intakeSourceId"
            required
            rules={[{ required: true, message: t.REQUIRED_FIELD }]}
          >
            <SelectLookupDto data={intakeSources} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <Form.Item label={t.DETAILS} name="referralDetails">
            <TextArea rows={4} placeholder={t.DETAILS} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default SourceInformation;
